import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/services/services.module.scss';

class Strategy extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isStrategy = 'Strategy';
    const headingStrategy = document.querySelector('.init_a_headingStrategy');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingStrategy, isStrategy);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  serviceList = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const serviceItem = document.querySelectorAll('.init_a_serviceItem');
      this.initAnimation.list(serviceItem);
      unobserve();
    }
  };

  serviceSkill = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const strategySkill = document.querySelector('.init_a_serviceSkill');
      this.initAnimation.list(strategySkill);
      unobserve();
    }
  };

  render() {
    const StrategyQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/services_strategy.png" }){
          childImageSharp {
            fluid(maxWidth: 703, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        badge: file(relativePath: { eq: "illustration/icon_strategy.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const optionsSkill = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={StrategyQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <ParallaxProvider>
                <main className={`services ${Style.services}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text">Strategy</span>
                        <span className="clip clipStrategy init_a_headingStrategy">Strategy</span>
                      </h1>
                      <p className={`intro init_a_introServices ${Style.intro}`}>課題や価値発見のリサーチ・分析に基づき、ユーザーの行動に合わせたメディア施策を設計し、アウトプットまでの一貫した実行が可能です。コンテンツ制作の現場で培った観察眼を活かして、ユーザーに届く戦略を設計します。</p>
                    </div>
                    <Parallax className={`${Style.illustration} ${Style.illustrationStrategy}`} x={[8, -8]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Strategy"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.serviceList}>
                    <section className={`sectionContent sectionContentFirst ${Style.service}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            メディア・広告広報
                            <br />
                            のプラニング
                          </dt>
                          <dd className={Style.serviceItemDescription}>企業や組織が訴求したいメッセージを、ユーザーに届けるための最適なプランを提案します。課題の発見や定義、KPI（重要業績評価指標）の設計、ブランドやコンセプト戦略、メディアの活用設計などを立案。また、コンサルティングやチームビルディング等の体制構築を支援し、プロジェクトを成功に導きます。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            課題解決の
                            <br />
                            リサーチ&分析
                          </dt>
                          <dd className={Style.serviceItemDescription}>サービスプロバイダやユーザー参加型・観察型のワークショップやリサーチ手法を活用し、課題発見や問題解決の手がかりを探します。また、ウェブツールを活用したマーケットリサーチやアクセス解析を行い、施策前のデータ分析から施策後の効果測定を実施。定性・定量の両アプローチから課題解決に役立てます。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            プロトタイピングと
                            <br />
                            コンセプト開発
                          </dt>
                          <dd className={Style.serviceItemDescription}>リサーチや分析に基づき、製品やサービスのネーミングやビジュアル等を含むコンセプト開発を行い、事業活動を言語化・可視化することで、組織内外のアクションを加速可能なものにします。また、クリエイティブ力を活かした短期間でのプロトタイピングや予備調査を行い、市場から選ばれるブランドを創ります。</dd>
                        </dl>
                      </div>
                    </section>
                  </Observer>
                  <Observer {...optionsSkill} onChange={this.serviceSkill}>
                    <section className={`sectionContent ${Style.skillup}`}>
                      <div className={`inner init_a_serviceSkill ${Style.inner}`}>
                        <div className={Style.skillupIllustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.badge.childImageSharp.fluid}
                            alt="Mediagrowth"
                          />
                        </div>
                        <div className={Style.right}>
                          <h4>ゲーム形式のワークショップ</h4>
                          <p>コンセプト開発や課題発見のためのリサーチや分析に役立てる参加型ワークショップを必要に応じて提案しています。ゲーム感覚でのワークを通じて、事業の実現を妨げてきた「思い込み」をほぐし、提供できる価値を探します。</p>
                        </div>
                      </div>
                    </section>
                  </Observer>
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Strategy;
